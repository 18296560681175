@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .topPart {
    background-color: variable.$whiteColor;
    color: variable.$primaryColor;
    padding: 5px 10px;
    transform: translateY(0%);
    transition: all 0.25s;
    width: 100%;
    padding-bottom: 15px;
    box-shadow: 1px 2px 4px 1px variable.$primaryColor;
    .actionsLabel {
      text-align: center;
      font-size: variable.$size15;
      font-family: variable.$primaryFontBold;
    }
    .actions {
      display: flex;
      gap: 10px;
      flex: 1;
      &.hasOnlyButtons {
        gap: 4px;
        a,
        button {
          font-size: variable.$size11;
          width: auto;
          padding: 0 5px;
          flex: auto;
          word-break: break-all;
        }
      }
      a,
      button {
        box-shadow: none;
        padding: 0 15px;
        font-size: 16px;
        height: 38px;
        line-height: 38px;
        border-radius: 50px;
        white-space: nowrap;
      }
    }
  }
  .bottomPart {
    background-color: variable.$orangeColor;
    padding: 10px 6px;
    position: relative;
    width: 100%;
    .icon {
      img {
        width: 55px;
        display: none;
      }
    }
    .text {
      font-family: variable.$primaryFont;
      font-size: variable.$size16;
      line-height: 16px;
      flex: 1;
      color: variable.$whiteColor;
      text-align: center;
      &::first-letter {
        text-transform: uppercase;
      }
      strong {
        color: variable.$whiteColor;
        display: inline;
        padding: 0;
        font-family: variable.$primaryFontBold;
      }
      em {
        text-transform: uppercase;
        text-decoration: underline;
        font-family: variable.$primaryFontBold;
      }
    }
  }
  .primary {
    @include mixin.buttonDefault();
  }
}
@include mixin.mediaSmallTabletSize() {
  .bar {
    &.showTopPart {
      .topPart {
        transform: none;
        padding-bottom: 10px;
      }
    }
    .topPart {
      position: relative;
      transform: none;
      &.isProgramPage {
        transform: none;
      }
    }
  }
}
@include mixin.mediaTabletSize() {
  .bar {
    .flex {
      gap: 30px;
    }
    .topPart {
      padding: 10px;
      .actionsLabel {
        font-size: variable.$size20;
      }
      .actions {
        flex: none;
        gap: 20px;
        &.hasOnlyButtons {
          width: 100%;
          max-width: 610px;
          gap: 10px;
          a,
          button {
            flex: 1;
            font-size: variable.$size16;
          }
        }
        a {
          width: max-content;
          min-width: 170px;
        }
      }
    }
  }
}
